import { useEffect, useState } from "react";
import { getData, getLocations } from "../api/request";
import InfoBox from "../components/InfoBox";
import { Link } from "react-router-dom";
import { FiDownload, FiPlus } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import MultipleDropdown from "./MultipleDropdown";
import * as XLSX from 'xlsx';

function DataList(props) {
    const initialize = {
        locations: [],
        results: [],
        dates: [],
        year: []
    }

    const [data, setData] = useState(null);
    const [info, setInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [locations, setLocations] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedResult, setSelectedResult] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [dates, setDates] = useState(null);

    const [filterData, setFilterData] = useState(initialize);

    useEffect(() => {
        var user = localStorage.getItem("skysan");
        user = JSON.parse(user);
        setCurrentUser(user);
        const fetchData = async () => {
            try {
                const res = await getData({
                    userID: user._id,
                    results: [],
                    locations: [],
                    dates: []
                });

                if (res.status) {
                    setData(res.data);
                    if (!dates) {
                        setDates([...new Set(res.data.map(item => item.date))])
                    }
                } else {
                    console.log(res.error);
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        const fetchLocations = async () => {
            try {
                const res = await getLocations(user._id);
                if (res.status) {
                    setLocations(res.locations.filter(item => item.isActive));
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
        fetchLocations();
    }, [dates]);

    const columns = ["PCT", "Bölüm", "Ekipman Adı", "Marka (Model)", "Son Kontrol", "Sonraki Kontrol", "Sonuç"];

    useEffect(() => {
        const handleFilter = async () => {
            var selectedLocationID = [];
            if (selectedLocation.length > 0) {
                selectedLocationID = selectedLocation.map(item => {
                    const location = locations.find(l => l.location === item);
                    return location ? location._id : null;
                });
            }

            try {
                setData(null);
                setIsLoading(true);
                const res = await getData({
                    userID: currentUser._id,
                    results: selectedResult,
                    locations: selectedLocationID,
                    dates: selectedDate,
                    year: selectedYear
                });

                if (res.status) {
                    setData(res.data);
                    console.log(res.data)
                } else {
                    console.log(res.error);
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        handleFilter();
        // eslint-disable-next-line
    }, [selectedLocation, selectedResult, selectedDate, currentUser?._id, selectedYear]);

    const handleDownload = async () => {
        const excelData = [
            [
                "PCT",
                "Bölüm",
                "Ekipman Kategori",
                "Ekipman Adı",
                "Marka (Model)",
                "Son Kontrol",
                "Sonraki Kontrol",
                "Sonuç",
                "Kusur Açıklamaları",
                "Alınan Aksiyonlar"
            ]
        ];

        for (const item of data) {
            excelData.push([
                item.number,
                item.location,
                item.equipmentCategory,
                item.equipmentName,
                item.brand,
                item.date,
                item.nextDate,
                item.result,
                item.explanation,
                item.actionTaken
            ])
        }

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(excelData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Kayıtlar.xlsx');
    };

    const handleText = (name, value) => {
        setFilterData({ ...filterData, [name]: value });

        if (name === "date") {
            setSelectedDate(value.length === 0 ? [] : value);
        }

        if (name === "result") {
            setSelectedResult(value.length === 0 ? [] : value);
        }

        if (name === "location") {
            setSelectedLocation(value.length === 0 ? [] : value);
        }

        if (name === "year") {
            setSelectedYear(value.length === 0 ? [] : value);
        }
    };

    return (
        <div className="h-screen">
            <div className="headerBar">
                <p>Kayıtlar</p>

                <div className="flex flex-row">
                    <div className='btn-download mr-3'
                        onClick={handleDownload}>
                        <FiDownload className='w-5 h-5 mr-1' />
                        <p>İndir</p>
                    </div>

                    <Link to='/data/add-data' className='w-max h-max'>
                        <div className='btn-primary'>
                            <FiPlus className='w-5 h-5 mr-1' />
                            <p>Kayıt</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="flex flex-row gap-4">
                {
                    locations &&
                    <div className="w-64 mb-4">
                        <MultipleDropdown
                            name="location"
                            data={locations.map(item => item.location)}
                            placeholder="Seçim yapın"
                            title="Bölüm"
                            selectedItems={filterData.results}
                            onToggle={handleText}
                        />
                    </div>
                }

                {
                    locations &&
                    <div className="w-64 mb-4">
                        <MultipleDropdown
                            name="result"
                            data={["Uygun", "Uygun Değil", "Kontrolsüz", "Rapor Bekliyor", "Ekipman Yenilendi"]}
                            placeholder="Seçim yapın"
                            title="Sonuç"
                            selectedItems={filterData.results}
                            onToggle={handleText}
                        />
                    </div>
                }

                {
                    dates &&
                    <div className="w-64 mb-4">
                        <MultipleDropdown
                            name="date"
                            data={dates}
                            placeholder="Seçim yapın"
                            title="Tarih"
                            selectedItems={filterData.dates}
                            onToggle={handleText}
                        />
                    </div>
                }

                {
                    dates &&
                    <div className="w-64 mb-4">
                        <MultipleDropdown
                            name="year"
                            data={[...new Set(dates.map(date => date.split(".")[2]))]}
                            placeholder="Seçim yapın"
                            title="Yıl"
                            selectedItems={filterData.year}
                            onToggle={handleText}
                        />
                    </div>
                }

            </div>
            {data
                ?
                <div className='flex flex-col shadow-lg overflow-x-auto rounded'>
                    <table>
                        <thead
                            className="border-b bg-white text-slate-700 font-medium text-sm">
                            <tr>
                                {columns.map((column, index) => (
                                    <th scope="col" className="px-6 py-3 text-left" key={index}>{column}</th>
                                ))}
                                <th scope="col" className='pl-4 py-3'></th>
                                <th scope="col" className='pl-4 py-3'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr className={`border-b bg-white hover:bg-slate-100 text-sm`} key={index}>
                                    <td className="px-6 py-3 text-left">{row.number}</td>
                                    <td className="px-6 py-3 text-left">{row.location}</td>
                                    <td className="px-6 py-3 text-left">{row.equipmentName}</td>
                                    <td className="px-6 py-3 text-left">{row.brand}</td>
                                    <td className="px-6 py-3 text-left">{row.date}</td>
                                    <td className="px-6 py-3 text-left">{row.nextDate}</td>
                                    <td className="px-6 py-3 text-left">
                                        {(() => {
                                            switch (row.result) {
                                                case "Uygun":
                                                    return (
                                                        <div className="bg-green-600 text-white rounded p-2 flex justify-center text-center">
                                                            {row.result}
                                                        </div>
                                                    );
                                                case "Uygun Değil":
                                                    return (
                                                        <div className="bg-red-600 text-white rounded p-2 flex justify-center text-center">
                                                            {row.result}
                                                        </div>
                                                    );
                                                case "Kontrolsüz":
                                                    return (
                                                        <div className="bg-yellow-500 text-white rounded p-2 flex justify-center text-center">
                                                            {row.result}
                                                        </div>
                                                    );
                                                case "Rapor Bekliyor":
                                                    return (
                                                        <div className="bg-slate-600 text-white rounded p-2 flex justify-center text-center">
                                                            {row.result}
                                                        </div>
                                                    );
                                                case "Ekipman Yenilendi":
                                                    return (
                                                        <div className="bg-purple-700 text-white rounded p-2 flex justify-center text-center">
                                                            {row.result}
                                                        </div>
                                                    );
                                                default:
                                                    return (
                                                        <div className="bg-green-600 text-white rounded p-2 flex justify-center text-center">
                                                            {row.result}
                                                        </div>
                                                    );
                                            }
                                        })()}
                                    </td>
                                    <td className="px-6 py-3">
                                        <div
                                            onClick={() => {
                                                props.dataDetail(data[index]);
                                                props.pageIsDataDetail(true);
                                            }}
                                            className="btn-primary">Detay</div>
                                    </td>
                                    <td className="px-6 py-3">
                                        {
                                            row.report &&
                                            <MdAttachFile className="w-5 h-5" />

                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                :
                isLoading
                    ? <div className="headerBar">
                        <p>Veriler yükleniyor</p>
                    </div>
                    : ""
            }

            {
                info ? <InfoBox content={info} /> : ""
            }
        </div>
    )
}

export default DataList;